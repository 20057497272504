/**
 * Should contain all active Posthog feature flags, mapping
 * human-readable enum key to the feature flag ID within posthog.
 */
export enum FeatureFlag {
  UseBlocksRestricted = "use-blocks-restricted",
  UseCreatorPersonas = "use-creator-personas",
  UseCreatorList = "use-creator-list",
  UseTransferOwnership = "use-transfer-ownership",
  UseOrgLogo = "use-org-logo",
  UseNewOnboarding = "use-new-onboarding",
}
